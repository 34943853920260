
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { AddHandoverMatter } from '@/types/handover.d'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})

export default class HandoverMatterAdd extends Vue {
  @Prop({ default: false }) showDialog!: boolean
  private formInfo: AddHandoverMatter = this.formInfoBak
  get formInfoBak () {
    return {
      /** 项目ID */
      projectId: '',
      /** 地址 */
      address: '',
      /** 事项内容 */
      issueContent: '',
      /** 事项处理附件 */
      imgFileList: []
    }
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: ['blur', 'change'] }
    ],
    issueContent: [
      { required: true, message: '请输入事项内容', trigger: 'change' }
    ]
  }

  private submitShow = false

  get projectList () {
    return this.$store.state.projectList
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.handover.insertTransferIssue, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    ;(this.formInfo as AddHandoverMatter).imgFileList.push(file)
  }

  imgRemove (index: number) {
    this.formInfo.imgFileList.splice(index, 1)
  }

  closeDialog () {
    this.formInfo = this.formInfoBak
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).clearValidate()
    })
    this.$emit('update:showDialog', false)
  }
}
