
import { Component, Vue } from 'vue-property-decorator'
import { HandoverMatterInfo } from '@/types/handover'
import HandoverMatterAdd from './HandoverMatterAdd.vue'
import HandoverMatterHandle from './HandoverMatterHandle.vue'
@Component({
  components: { HandoverMatterAdd, HandoverMatterHandle }
})

export default class HandoverMatter extends Vue {
  private searchInfo = {
    projectId: '',
    dealStatus: '',
    startTime: '',
    endTime: ''
  }

  private pickerMinDate = ''
  private date =[]

  private pickerOptions = {
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.pickerMinDate = date.minDate
    },
    disabledDate: (time: any) => {
      if (this.pickerMinDate !== '') {
        return (time.getTime() > new Date(this.pickerMinDate).getTime() + 60 * 60 * 24 * 30 * 1000) ||
        (time.getTime() >= Date.now()) ||
        (time.getTime() < new Date(this.pickerMinDate).getTime() - 60 * 60 * 24 * 30 * 1000)
      } else {
        return time.getTime() >= Date.now()
      }
    }
  }

  private page = 1
  private size = 10
  private total = 0
  private tableData: HandoverMatterInfo[] =[]
  private loading = false
  get projectList () {
    return this.$store.state.projectList
  }

  private showDialog = false
  private handleShowDialog = false
  private issueId = ''

  created () {
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    if (this.date) {
      this.searchInfo.startTime = this.date[0]
      this.searchInfo.endTime = this.date[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
    this.$axios.get<{ total: number; list: Array<HandoverMatterInfo> }>(this.$apis.handover.selectTransferIssueByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  addEvent () {
    this.showDialog = true
  }

  onDetail (id: string) {
    this.$router.push({ name: 'HandoverMatterDetail', params: { issueId: id } })
  }

  onDelete (issueId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.handover.deleteTransferIssue, { issueId: issueId }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onHandle (issueId: string) {
    this.handleShowDialog = true
    this.issueId = issueId
  }
}
